import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Nav } from 'react-bootstrap';

export default function PageNotFound() {
  useEffect(() => {
    document.querySelector('body').classList.add('loaded');
  }, []);

  return (
    <>
      <section className='body-error error-inside'>
        <div className='center-error'>
          <Row>
            <Col lg={8}>
              <div className='main-error mb-3'>
                <h2 className='error-code text-dark text-center font-weight-semibold m-0'>
                  404 <i className='fas fa-file'></i>
                </h2>
                <p className='error-explanation text-center'>
                  We're sorry, but the page you were looking for doesn't exist.
                </p>
              </div>
            </Col>

            <Col lg={4}>
              <h4 className='text'>Here are some useful links</h4>
              <Nav className='nav-list flex-column primary'>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to={`${process.env.PUBLIC_URL}/dashboard`}
                  >
                    <i className='fas fa-caret-right text-dark'></i> Dashboard
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} to={`${process.env.PUBLIC_URL}/`}>
                    <i className='fas fa-caret-right text-dark'></i> Sign In
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}
